<template>
  <div class="mb-4 flex space-x-4 text-base">
    <router-link
      :to="{ name: 'dashboard' }"
      active-class="is-active !font-semibold !pointer-events-none !bg-gray-200"
      exact
      class="flex flex-grow items-center justify-center space-x-3 rounded-lg p-4 py-3 hover:cursor-pointer hover:bg-gray-100 sm:inline-flex sm:flex-none"
    >
      <font-awesome-icon :icon="['fal', 'house-user']" />
      <div>Dashboard</div>
    </router-link>
    <router-link
      :to="{ name: 'order history' }"
      active-class="is-active !font-semibold !pointer-events-none !bg-gray-200"
      exact
      class="flex flex-grow items-center justify-center space-x-3 rounded-lg p-4 py-3 hover:cursor-pointer hover:bg-gray-100 sm:inline-flex sm:flex-none"
    >
      <font-awesome-icon :icon="['fal', 'box-open']" />
      <div class="dash-label">Order History</div>
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>
