var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col rounded-full",class:_vm.history.shipped ? 'bg-teal-300' : 'bg-gray-100'},[_c('div',[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"z-20 flex items-center justify-between space-x-2 rounded-full text-gray-700",class:[
          _vm.history.packedAt ? 'has-tippy' : '',
          _vm.isInline ? 'h-8 px-3' : 'h-10',
          _vm.history.shipped ? '' : 'bg-teal-400'
        ],attrs:{"data-tippy-content":_vm._f("formatDate")(_vm.history.packedAt,'do MMMM, yyyy hh:mmaaa')}},[(!_vm.history.shipped)?_c('span',{staticClass:"pointer-events-none relative flex h-2 w-2"},[_c('span',{staticClass:"absolute inline-flex h-full w-full animate-ping rounded-full bg-white opacity-75"}),_c('span',{staticClass:"relative inline-flex h-2 w-2 rounded-full bg-white"})]):_vm._e(),_c('div',{staticClass:"text-xs",class:_vm.history.shipped ? 'hidden' : 'font-semibold text-white'},[_vm._v(" "+_vm._s(_vm.history.shipped ? 'Packed' : 'Packing your box...')+" ")])]),_c('div',{staticClass:"z-20 flex flex-none items-center justify-between space-x-2 rounded-full px-3",class:[
          _vm.history.shipped
            ? 'has-tippy bg-teal-400 text-white'
            : 'text-gray-500',
          _vm.isInline ? 'h-8' : 'h-10 w-10'
        ],attrs:{"data-tippy-content":_vm._f("formatDate")(_vm.history.shippedAt,'do MMMM, yyyy hh:mmaaa')}},[_c('div',{staticClass:"text-xs",class:_vm.history.shipped ? 'font-semibold text-white' : 'text-gray-500'},[_vm._v(" On its way! ")]),_c('font-awesome-icon',{staticClass:"text-md mx-auto",class:_vm.history.shipped ? 'text-white' : 'text-gray-400',attrs:{"icon":['fad', 'truck-ramp-box']}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }