<template>
  <div>
    <div v-if="history && history.length > 0">
      <div class="grid grid-cols-1 gap-4">
        <div class="text-lg px-4 font-semibold ">
          Your Latest box...
        </div>
        <div class=" divide-y-2">
          <history-item
            :key="`latest-history-${item.invId}`"
            v-for="item in latestHistory"
            is-inline
            :item="item"
          />
        </div>

        <div v-if="otherHistory.length > 0" class="space-y-4">
          <div class="text-lg px-4 font-semibold ">
            Previous boxes...
          </div>
          <div class=" divide-y-2">
            <history-item
              :key="`other-history-${item.invId}`"
              v-for="item in otherHistory"
              is-inline
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center h-48 text-gray-500" v-else>
      There are no orders to display.
    </div>
  </div>
</template>

<script>
import { slice, pullAt } from 'lodash/fp';

import HistoryItem from '@/components/order/HistoryItem';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
export default {
  components: {
    HistoryItem
  },
  computed: {
    ...mapGetters({
      history: 'orderHistory'
    }),
    latestHistory() {
      return slice(0, 1, this.history);
    },
    otherHistory() {
      return pullAt(0, this.history);
    }
  },
  methods: {
    getStatus(item) {
      if (item.cancelled) {
        return 'Cancelled';
      }
      if (item.shipped) {
        return 'On its way!';
      }
      if (item.packed && !item.shipped) {
        return 'Ready for dispatch';
      }
      if (!item.packed && !item.shipped) {
        return 'Ready for dispatch';
      }
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yy hh:mmaaa');
    },
    getTasters(item) {
      const counts = {
        adult: 0,
        kitten: 0
      };
      const tasters = [];
      item.cats.forEach(c => {
        c.isKitten ? counts.kitten++ : counts.adult++;
      });
      if (counts.adult !== 0) {
        tasters.push(`${counts.adult} x Taster Box`);
      }
      if (counts.kitten !== 0) {
        tasters.push(`${counts.kitten} x Taster Box (Kitten)`);
      }
    }
  },
  mounted() {
    if (!this.history) {
      this.$store.dispatch('getOrderHistory');
    }
  }
};
</script>

<style lang="css" scoped></style>
