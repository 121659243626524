<template>
  <div class="container mx-auto  py-4 pt-0 sm:pt-4 ">
    <dashboard-nav />

    <history-items class="mt-8" />
  </div>
</template>

<script>
import DashboardNav from '@/components/DashboardNav';
import HistoryItems from '@/components/order/HistoryItems';
export default {
  components: {
    DashboardNav,
    HistoryItems
  }
};
</script>

<style lang="css" scoped></style>
