<template>
  <div
    class="flex flex-col rounded-full"
    :class="history.shipped ? 'bg-teal-300' : 'bg-gray-100'"
  >
    <div>
      <div class="flex items-center justify-between">
        <div
          class="z-20 flex items-center justify-between space-x-2 rounded-full text-gray-700"
          :class="[
            history.packedAt ? 'has-tippy' : '',
            isInline ? 'h-8 px-3' : 'h-10',
            history.shipped ? '' : 'bg-teal-400'
          ]"
          :data-tippy-content="
            history.packedAt | formatDate('do MMMM, yyyy hh:mmaaa')
          "
        >
          <span
            v-if="!history.shipped"
            class="pointer-events-none relative flex h-2 w-2"
          >
            <span
              class="absolute inline-flex h-full w-full animate-ping rounded-full bg-white opacity-75"
            ></span>
            <span
              class="relative inline-flex h-2 w-2 rounded-full bg-white"
            ></span>
          </span>

          <div
            class="text-xs"
            :class="history.shipped ? 'hidden' : 'font-semibold text-white'"
          >
            {{ history.shipped ? 'Packed' : 'Packing your box...' }}
          </div>
        </div>

        <div
          class="z-20 flex flex-none items-center justify-between space-x-2 rounded-full px-3"
          :class="[
            history.shipped
              ? 'has-tippy bg-teal-400 text-white'
              : 'text-gray-500',
            isInline ? 'h-8' : 'h-10 w-10'
          ]"
          :data-tippy-content="
            history.shippedAt | formatDate('do MMMM, yyyy hh:mmaaa')
          "
        >
          <div
            class="text-xs"
            :class="
              history.shipped ? 'font-semibold text-white' : 'text-gray-500'
            "
          >
            On its way!
          </div>
          <font-awesome-icon
            :icon="['fad', 'truck-ramp-box']"
            class="text-md mx-auto"
            :class="history.shipped ? 'text-white' : 'text-gray-400'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js';
export default {
  props: {
    history: {
      type: Object
    },
    isInline: {
      type: Boolean
    }
  },
  mounted() {
    tippy('.has-tippy', {
      placement: 'top',
      arrow: false,
      delay: [200, 0]
    });
  }
};
</script>

<style lang="css" scoped></style>
