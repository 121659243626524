<template>
  <div
    :key="`order-history-${item.invId}`"
    class=" flex flex-col sm:flex-row sm:items-center "
    :class="isInline ? 'text-sm p-4' : ''"
  >
    <div class="flex flex-col flex-none w-full sm:w-96">
      <key-value
        label="Order Ref"
        :value="[`#${item.invId}`, formatDate(item.createdAt)]"
      />
      <key-value>
        <div v-if="!currentDetails.settings">
          <div class="flex space-x-1">
            <div
              v-if="currentDetails.creditUsed > 0"
              class="line-through italic text-gray-500"
            >
              {{ item.cats[0].cost | formatPounds }}
            </div>
            <div>
              <div v-if="checkCharged(currentDetails.charged)">
                {{ getCharged(currentDetails.charged) }}
              </div>
              <div v-else>
                {{ getCharged(currentDetails.charged) }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex space-x-1">
            <div
              v-if="hasCredit || (hasCoupon && !coupons.onlyDelivery)"
              class="line-through italic text-gray-500"
            >
              {{ currentDetails.settings.originalSubscription | formatPounds }}
            </div>
            <div>
              {{ currentDetails.settings.finalSubscription | formatPounds }}
            </div>
          </div>
        </div>
        <div v-if="hasCoupon || hasCredit" class="mt-2 space-y-1 text-sm">
          <div
            v-for="coupon in currentDetails.coupons"
            class=""
            :key="coupon.key"
          >
            <div
              class="text-xs group items-center  space-x-2 inline-flex px-1 bg-blue-100 text-blue-600 rounded uppercase  "
            >
              <font-awesome-icon
                :icon="['fal', 'ticket-simple']"
                class="text-sm"
              />
              <div>{{ coupon.display }}</div>
            </div>
          </div>
          <div v-if="hasCredit">
            <div
              class="text-xs group items-center  space-x-2 inline-flex  px-1 bg-blue-100 text-blue-600 rounded uppercase  "
            >
              <font-awesome-icon
                :icon="['fal', 'ticket-simple']"
                class="text-sm"
              />
              <div>{{ currentDetails.creditUsed | formatPounds }} credit</div>
            </div>
          </div>
        </div>
      </key-value>
      <key-value v-if="hasExtras" label="Samples"
        ><div>
          {{ currentDetails.settings.finalExtras | formatPounds }}
        </div>
      </key-value>
      <key-value v-if="currentDetails.settings" label="Delivery Cost"
        ><div>
          {{ currentDetails.settings.finalDelivery | formatPounds }}
        </div>
      </key-value>

      <key-value
        label="Type"
        :value="item.isSubscription ? 'Subscription' : getTasters(item)"
      />

      <key-value
        label="Status"
        :value="getStatus(item)"
        :class="{ 'text-red-500': getStatus(item) === 'Cancelled' }"
      />
      <key-value class="mt-4" v-if="item.shipped">
        <div v-if="!item.trackingUrl" class="text-gray-400 italic">
          Tracking unavailable
        </div>
        <a v-else target="_blank" class="underline" :href="item.trackingUrl"
          >View tracking &rarr;</a
        >
      </key-value>
    </div>
    <div class="  mt-6 sm:mt-0 flex-grow sm:pl-12">
      <history-timeline
        :history="item"
        v-if="!item.cancelled"
        :is-inline="isInline"
      />
      <div v-else class="bg-red-100 p-4 rounded text-red-500">
        This order has been cancelled. If you believe this to be incorrect.
        Please contact us quoting your order number:
        {{ item.invId }}
      </div>
    </div>
  </div>
</template>

<script>
import HistoryTimeline from '@/components/order/HistoryTimeline';
import { format } from 'date-fns';
import { isNumber } from 'lodash/fp';
import utils from '@/utils';
import KeyValue from '@/components/KeyValue';
export default {
  name: 'HistoryItem',
  components: {
    HistoryTimeline,
    KeyValue
  },
  props: {
    item: {
      type: Object
    },
    isInline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentDetails() {
      return this.item.cats[0].currentDetails;
    },
    hasCredit() {
      return this.currentDetails.creditUsed > 0;
    },
    hasCoupon() {
      return this.currentDetails.coupons
        ? this.currentDetails.coupons.length > 0
        : false;
    },
    coupons() {
      return this.currentDetails.coupons
        ? utils.formatCoupons(this.currentDetails.coupons)
        : [];
    },
    hasExtras() {
      return this.currentDetails?.settings
        ? this.currentDetails?.settings?.finalExtras > 0
        : false;
    }
  },
  methods: {
    getStatus(item) {
      if (item.cancelled) {
        return 'Cancelled';
      }
      if (item.shipped) {
        return 'On its way!';
      }
      if (item.packed && !item.shipped) {
        return 'Ready for dispatch';
      }
      if (!item.packed && !item.shipped) {
        return 'Ready for dispatch';
      }
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yy hh:mmaaa');
    },
    getCharged(value) {
      return isNumber(value) ? this.formatValue(value) : '£?';
    },
    checkCharged(value) {
      return isNumber(value);
    },
    formatValue(val) {
      const pounds = val / 100;
      return `£${pounds.toLocaleString(undefined, {
        minmumSignificantDigits: 2
      })}`;
    },
    getTasters(item) {
      const counts = {
        adult: 0,
        kitten: 0
      };
      const tasters = [];
      item.cats.forEach(c => {
        c.isKitten ? counts.kitten++ : counts.adult++;
      });
      if (counts.adult !== 0) {
        tasters.push(`${counts.adult} x Taster Box`);
      }
      if (counts.kitten !== 0) {
        tasters.push(`${counts.kitten} x Taster Box (Kitten)`);
      }

      return tasters;
    }
  }
};
</script>

<style></style>
