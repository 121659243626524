<template>
  <div class="flex py-1">
    <div class="text-gray-500 w-1/3 sm:w-48">{{ label }}</div>
    <div v-if="!isMultiline">
      <router-link
        class="underline text-indigo-500"
        v-if="hasLink"
        :to="hasLink"
        target="_blank"
        >{{ value }}</router-link
      >
      <div v-else>
        {{ value }}
      </div>
      <slot></slot>
    </div>
    <div v-else>
      <div v-for="(val, index) in value" :key="`line-${index}`">
        {{ val }}
      </div>
    </div>
  </div>
</template>

<script>
import { isArray } from 'lodash/fp';
export default {
  props: {
    label: {
      type: String
    },
    value: {
      type: [String, Array]
    },
    hasLink: {
      type: Object
    }
  },
  computed: {
    isMultiline() {
      return isArray(this.value);
    }
  }
};
</script>

<style lang="css" scoped></style>
